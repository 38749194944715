export const isDevelopment = () =>
	typeof window === "undefined" ||
	process.env.NODE_ENV === "development" ||
	window.location.host === "localhost:3000" ||
	window.location.host === "arcade-master.arcadexr.net"

export const isMasterDevelopment = () =>
	typeof window === "undefined" ||
	process.env.NODE_ENV === "development" ||
	window.location.host === "arcade-master.arcadexr.net"

export const isStaging = () =>
	typeof window !== "undefined" && window.location.host === "arcade-staging1.arcadexr.net"

export const isLive = () =>
	typeof window !== "undefined" && window.location.host === "arcade.online"

export const getEnvPath = () => {
	if (isLive()) return "https://arcade.online"
	if (isDevelopment()) {
		if (window.location.host === "localhost:3000") return "https://localhost:8443"
		return "https://arcade-master.arcadexr.net"
	}
	if (isStaging()) return "https://arcade-staging1.arcadexr.net"
}
