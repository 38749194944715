export interface SentryConfig {
	dsn: string
	sampleRate: number
	isActiveOnLocalhost: boolean
	isActiveOnMaster: boolean
}

export const sentryConfig: SentryConfig = {
	dsn: "https://f969eeea459648d5b0597cba252fdc2e@o1288696.ingest.sentry.io/6516602",
	sampleRate: 0.5,
	isActiveOnLocalhost: false,
	isActiveOnMaster: false,
}
