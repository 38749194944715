import type { BrowserOptions, Event, EventHint } from "@sentry/react"
import { BrowserTracing, init, setUser } from "@sentry/react"
import scriptVersion from "version/version.json"
import { SentryConfig, sentryConfig } from "./config"

let dsn = ""

// to update user of sentry when user has login / logout
export const SentrySetUser = setUser

export async function initSentry() {
	let config: SentryConfig

	try {
		config = sentryConfig
	} catch (err) {
		console.log("Failed to load sentry")
		return
	}

	// sending local development error to sentry
	if (!config.isActiveOnLocalhost && window.location.host.includes("localhost")) return
	if (!config.isActiveOnMaster && window.location.host.includes("arcade-master")) {
		return
	}

	const { dsn: deviceDSN, sampleRate = 1 } = config

	if (deviceDSN) {
		dsn = deviceDSN
	} else if (!deviceDSN && !dsn) {
		console.error("dsn is empty")
	}

	const sentryOptions: BrowserOptions = {
		integrations: [
			new BrowserTracing(),
			// new Replay({
			// 	// maximum duration to 5mins
			// 	maxReplayDuration: 5000,
			// }),
		],
		tracesSampleRate: 1.0,
		sampleRate,
		release: scriptVersion,
		environment: process.env.NODE_ENV,
		dsn,
		ignoreErrors: [/Large\sHTTP\spayload\serror/i],
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// https://docs.sentry.io/error-reporting/configuration/filtering/
		beforeSend: eventFilter,
		// https://github.com/getsentry/sentry-javascript/issues/3388#issuecomment-823219514
		// defaultIntegrations: defaultIntegrations.map(integration => {
		// 	if (integration.name === "TryCatch") {
		// 		return new Integrations.TryCatch({
		// 			requestAnimationFrame: !navigator.userAgent.includes("Chrome/74.0.3729"),
		// 		})
		// 	}
		// 	return integration
		// }),
	}

	// typeof sentryOptions.defaultIntegration only accept false (not boolean)
	if (process.env.NODE_ENV === "development") {
		sentryOptions.defaultIntegrations = false
	}

	init(sentryOptions)
	setUser({ userAgent: navigator.userAgent })
}

/**
 *
 * Private HELPER FUNCTIONS
 *
 */

function eventFilter(event: Event, hint?: EventHint | undefined) {
	if (!event.exception || !event.exception.values) return event

	const errorMsg = event.exception.values[0].value
	if (filterByErrorMsg(errorMsg)) {
		return null
	}

	if (hint) {
		let error = hint.originalException

		if (typeof error === "string") {
			error = new Error(error)
		}
	}
	return event
}

const filteredErrors: string[] = [
	"The client has already been terminated.",
	"Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked).",
]
function filterByErrorMsg(errorMsg = "") {
	if (errorMsg.includes("AbortError") || errorMsg.includes("IDBDatabase")) return true
	if (filteredErrors.includes(errorMsg)) {
		return true
	}
	return false
}
